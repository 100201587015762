import {PassportInterface} from "../interfaces/PassportInterface";
import {ImpactFigureCalculationTypeInterface} from "../interfaces/ImpactFigureCalculationTypeInterface";
import {passportHasManualImpact} from "./passportHasManualImpact";


export const getManualImpactFigure = (passport: PassportInterface, impactFigureCalculationTypes?: ImpactFigureCalculationTypeInterface[]) => {
    return passport.passportImpactFigures?.find((impactFigure) => {
        return impactFigure.passportImpactFigureCalculationTypeId === impactFigureCalculationTypes?.find((type) => type.code === 'manual')?.id
    })
}

interface GetCurrentImpactFigureArgs {
    passport: PassportInterface
    activeImpactFigureTypeId?: number,
    impactFigureCalculationTypes?: ImpactFigureCalculationTypeInterface[],
    overruleEndUseId?: number
}

export const getCurrentImpactFigure = (args: GetCurrentImpactFigureArgs) => {

    const {passport, activeImpactFigureTypeId, impactFigureCalculationTypes, overruleEndUseId} = args

    return passport.passportImpactFigures?.find((impactFigure) => {

        // If activeImpactFigureTypeId is manual, return the manual impact figure
        if (passportHasManualImpact(activeImpactFigureTypeId, impactFigureCalculationTypes)) {
            return impactFigure.passportImpactFigureCalculationTypeId === activeImpactFigureTypeId
        }

        // If overruleEndUseId is set, use that as the endUseSolution
        if (overruleEndUseId) {
            return impactFigure.passportImpactFigureCalculationTypeId === activeImpactFigureTypeId && impactFigure.materialImpactFigureTypeId === overruleEndUseId
        }

        // Second check is dumb but calculateEndOfUse could be 1 with a endOfUseSolution set on passport. In this case ignore calculateEndOfUse
        if (typeof passport.endOfUseSolution === "undefined" || (passport.calculateEndOfUse && passport.endOfUseSolution)) {
            return impactFigure.passportImpactFigureCalculationTypeId === activeImpactFigureTypeId && impactFigure.materialImpactFigureTypeId === null
        }

        return impactFigure.passportImpactFigureCalculationTypeId === activeImpactFigureTypeId && impactFigure.materialImpactFigureTypeId === passport.endOfUseSolution.id
    })
}

export const getCurrentImpactFigureType = (impactFigureCalculationTypes?: ImpactFigureCalculationTypeInterface[], activeImpactFigureTypeId?: number | null) => {
    return impactFigureCalculationTypes?.find((impactFigureCalculationType) => impactFigureCalculationType.id === activeImpactFigureTypeId)
}